.navbar {
    width: 100%;
    background-color: white; 
    color: black;
  }
  
  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 7%;
  }
  
  .navbar-logo {
    color: black; 
    text-decoration: none;
    font-size: 24px;
  }
  
  .navbar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .navbar-item {
    display: inline-block;
    margin: 10px 20px;
  }
  
  .navbar-link {
    color: black;
    text-decoration: none;
    font-size: 20px;
    cursor: pointer;
  }
  